<template>
  <div class="pagePrint">
    <table border="1" cellspacing="0" cellpadding="0">
      <thead>
        <tr>
          <td>
            <img src="../../assets/images/logo.png" alt="" />
            <div style="color: #999999">万旅集团旗下官方预定平台</div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>订单号</td>
          <td>
            <span>{{ orderDetail.coding }}</span>
          </td>
        </tr>
        <tr>
          <td>实际支付价格</td>
          <td>
            <span>￥ {{ orderDetail.endPrice }}</span>
          </td>
        </tr>
        <tr>
          <td>优惠活动</td>
          <td>
            <span>{{ orderDetail.couponName || "未参加活动" }}</span>
          </td>
        </tr>
        <tr>
          <td>客户备注</td>
          <td>
            <span>{{ orderDetail.remark || "暂无备注" }}</span>
          </td>
        </tr>
        <tr>
          <td>客户姓名加手机</td>
          <td>
            <span>姓名: {{ orderDetail.linkMan }}</span>
            <span style="margin-left: 20px">手机: {{ orderDetail.phone }}</span>
          </td>
        </tr>
        <tr v-if="orderDetail.orderType === 1">
          <td>入住人</td>
          <td>
            <div v-for="item in orderDetail.peopleInfos" :key="item.idNo">
              <span>姓名: {{ item.trueName }} </span>
              <span style="margin-left: 8px">{{ item.strType }}:</span>
              <span> {{ item.idNo }}</span>
            </div>
          </td>
        </tr>
        <tr v-if="orderDetail.orderType !== 1">
          <td>游玩时间</td>
          <td>
            <span>{{ orderDetail.beginTime }}</span>
          </td>
        </tr>
        <tr v-if="orderDetail.orderType === 1">
          <td>入住日期和离店日期</td>
          <td>
            <span
              >{{ orderDetail.beginTime }} 至 {{ orderDetail.endTime }}
            </span>
          </td>
        </tr>
        <tr>
          <td>产品名称</td>
          <td>
            <span>{{
              orderDetail.upgradeId > 0
                ? orderDetail.upProdName
                : orderDetail.prodName
            }}</span>
          </td>
        </tr>
        <tr v-if="orderDetail.orderType !== 1">
          <td>单价</td>
          <td>
            <span> ￥ {{ orderDetail.unitPrice }}/份</span>
          </td>
        </tr>
        <tr>
          <td>购买数量</td>
          <td>
            <span> {{ orderDetail.count }}</span>
          </td>
        </tr>
        <tr v-if="orderDetail.childCount">
          <td>儿童数量</td>
          <td>
            <span> {{ orderDetail.childCount }}</span>
          </td>
        </tr>
        <tr v-if="orderDetail.orderType === 1">
          <td>入住时价格列表</td>
          <td>
            <div
              v-for="item in orderDetail.roomPriceDayModel"
              :key="item.addTime"
            >
              <span>日期: {{ item.addTime }}</span>
              <span style="margin-left: 18px">价格: ￥{{ item.price }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td>总价</td>
          <td>
            <span> ￥{{ orderDetail.totalPrice }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: "pagePrint",
  props: {
    coding: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      orderDetail: {},
    };
  },
  created() {
    this.orderDetail = JSON.parse(window.localStorage.getItem("printData"));
    this.$nextTick(() => {
      window.print();
    });
  },
  methods: {
    async getrefundOrderdetail() {
      const { data } = await refundOrderdetail({
        coding: this.coding,
      });
      if (data.code == 0) {
        data.data[0].peopleInfos = [];
        if (data.data[0].peopleInfo) {
          data.data[0].peopleInfo.map((item) => {
            let obj = {
              trueName: item.split("_")[0],
              strType: item.split("_")[1],
              idNo: item.split("_")[2],
            };
            data.data[0].peopleInfos.push(obj);
          });
        }
        if (data.data[0].remainTime > 0) {
          data.data[0].countDown = this.formatMilliseconds(
            data.data[0].remainTime
          );
        } else {
          data.data[0].countDown = "00:00:00";
        }

        this.refundPrice = data.data[0].refundPrice;
        this.refundIntegral = data.data[0].refundIntegral;

        this.orderDetail = data.data[0];
        this.$nextTick(() => {
          window.print();
        });
      }
    },
    formatMilliseconds(value) {
      var second = parseInt(value) / 1000; // second
      var minute = 0; // minute
      var hour = 0; // hour
      if (second > 60) {
        minute = parseInt(second / 60);
        second = parseInt(second % 60);
        if (minute > 60) {
          hour = parseInt(minute / 60);
          minute = parseInt(minute % 60);
        }
      }
      var result;
      if (second >= 10) {
        result = "" + parseInt(second);
      } else {
        result = "" + "0" + parseInt(second);
      }
      if (minute >= 10) {
        result = "" + parseInt(minute) + ":" + result;
      } else {
        result = "" + "0" + parseInt(minute) + ":" + result;
      }
      if (hour >= 10) {
        result = "" + parseInt(hour) + ":" + result;
      } else {
        result = "" + "0" + parseInt(hour) + ":" + result;
      }
      return result;
    },
  },
};
</script>
<style lang="less" scoped>
.pagePrint {
  table {
    margin-left: 60px;
    border-color: rgb(153, 153, 153) !important;
    thead {
      tr {
        td {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 120px;
        }
      }
    }
    tbody {
      tr {
        display: flex;
        height: 60px;
        td {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          &:nth-child(1) {
            width: 250px;
            text-align: end;
            padding-right: 30px;
          }
          &:nth-child(2) {
            justify-content: flex-start;
            padding-left: 30px;
            padding: 0 30px;
            width: 100%;
            color: rgb(153, 153, 153);
          }
        }
      }
    }
  }
  /deep/.el-form {
    .el-form-item {
      .el-form-item__label {
        width: 200px !important;
      }
      .el-form-item__content {
        margin-left: 200px;
      }
    }
  }
}
</style>